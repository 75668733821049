import { deleteVehicle, deleteVehicleImage, getVehicles, patchVehicle, postVehicle, postVehicleImages } from "@/api";
import { UserVehicle } from "@/models";
import { HalLink } from "@points/common";
import { defineStore } from "pinia";

export const useUserVehicleStore = defineStore("user-vehicle", {
    state: () => ({
        vehicles: [] as UserVehicle[],
        currentVehicle: {} as Partial<UserVehicle>
    }),
    actions: {
        async loadVehicles(): Promise<void> {
            this.vehicles = await getVehicles();

            this.vehicles.forEach((vehicle: UserVehicle) => {
                if (vehicle._links?.images && !Array.isArray(vehicle._links?.images)) {
                    vehicle._links.images = [vehicle._links.images];
                }
            });
        },

        async saveVehicle(vehicle: UserVehicle): Promise<void> {
            const newVehicle = await postVehicle(vehicle);
            this.vehicles.push(newVehicle);
        },

        async updateVehicle(vehicle: UserVehicle): Promise<void> {
            if (!vehicle.id) {
                throw new Error("vehicle hasn't been loaded");
            }

            // TODO Improve this so specific fields can be immutable
            vehicle.yearId = this.currentVehicle.yearId;
            vehicle.make = this.currentVehicle.make;
            vehicle.model = this.currentVehicle.model;
            vehicle.submodel = this.currentVehicle.submodel;

            const newVehicle = await patchVehicle(this.currentVehicle, vehicle);

            if (newVehicle) {
                if (newVehicle._links?.images && !Array.isArray(newVehicle._links?.images)) {
                    newVehicle._links.images = [newVehicle._links.images];
                }

                const index = this.vehicles.findIndex((v) => v.id === this.currentVehicle.id);
                if (index > -1) {
                    this.vehicles[index] = newVehicle;
                } else {
                    this.vehicles.push(newVehicle);
                }
            }
        },

        async deleteVehicle(id: string): Promise<void> {
            await deleteVehicle(id);
            const index = this.vehicles.findIndex((vehicle) => vehicle.id === id);
            if (index > -1) {
                this.vehicles.splice(index, 1);
            }
        },

        async uploadVehicleImages(images: FileList): Promise<string[]> {
            if (!this.currentVehicle.id) {
                throw new Error("currentVehicle hasn't been loaded");
            }

            const imageUris = await postVehicleImages(this.currentVehicle.id || "", images);
            const imageLinks = imageUris.map((imageUri) => ({ href: imageUri } as HalLink));
            const newVehicle = JSON.parse(JSON.stringify(this.currentVehicle)) as UserVehicle;

            if (newVehicle._links?.images) {
                (newVehicle._links.images as HalLink[]).push(...imageLinks);
                this.currentVehicle = newVehicle;

                const index = this.vehicles.findIndex((v) => v.id === this.currentVehicle.id);
                if (index > -1) {
                    this.vehicles[index] = newVehicle;
                } else {
                    this.vehicles.push(newVehicle);
                }
            }

            return imageUris;
        },

        async deleteVehicleImage(id: string): Promise<void> {
            if (!this.currentVehicle.id) {
                throw new Error("currentVehicle hasn't been loaded");
            }

            await deleteVehicleImage(this.currentVehicle.id || "", id);

            const newVehicle = Object.assign({}, this.currentVehicle);

            if (newVehicle._links?.images) {
                const index = (this.currentVehicle._links?.images as HalLink[]).findIndex((link) => link.href.endsWith(id));
                if (index > -1) {
                    (this.currentVehicle._links?.images as HalLink[]).splice(index, 1);
                }
            }
        }
    }
});

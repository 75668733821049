import { useUserStore } from "@/stores";
import { HttpStatusCode, useOidcStore } from "@points/common";
import { AxiosError } from "axios";
import { NavigationGuardNext, Route } from "vue-router";

export async function authNavigationGuard(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
    const oidcStore = useOidcStore();
    const userStore = useUserStore();

    if (to.matched.some((record) => record.meta.requiresAuth) &&
        oidcStore.isAuthenticated &&
        !userStore.currentUser?.id) {
        try {
            await userStore.loadCurrentUser();
            next();
        } catch (error: unknown) {
            const axiosError = error as AxiosError;
            if (axiosError.isAxiosError && axiosError?.response?.status === HttpStatusCode.NotFound) {
                try {
                    await userStore.createUser();
                    next();
                } catch {
                    next({ name: "home" });
                }
            } else {
                next({ name: "home" });
            }
        }
    } else {
        next();
    }
}

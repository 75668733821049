
import { AllTracCustomer, UserSettings } from "@/models";
import { useAlltracCustomerStore, useAppointmentStore, useChallengeStore, useUserStore } from "@/stores";
import { useActionStore, useOidcStore } from "@points/common";
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
    computed: {
        ...mapState(useAlltracCustomerStore, ["allTracCustomer"]),
        ...mapState(useChallengeStore, ["challengeSuccessToken"]),
        ...mapState(useUserStore, ["currentUser"])
    },

    methods: {
        ...mapActions(useActionStore, ["executeAction", "isActionPending"]),
        ...mapActions(useUserStore, ["updateCurrentUser"]),
        ...mapActions(useAlltracCustomerStore, ["saveAllTracCustomer"]),
        ...mapActions(useAppointmentStore, ["loadAppointments"]),

        async continueToDashboard(): Promise<void> {
            const oidcStore = useOidcStore();

            if ((oidcStore.userSettings as UserSettings).accountsHasAllTracRecord && this.challengeSuccessToken?.customerId) {
                const newUser = Object.assign({}, this.currentUser);
                newUser.allTracCustomerId = this.challengeSuccessToken.customerId;

                await this.executeAction(
                    this.updateCurrentUser({
                        newUser: newUser,
                        token: this.challengeSuccessToken.token
                    }),
                    "accountConnectionCompleteUpdateUser"
                );

                await this.loadAppointments();

            } else if ((oidcStore.userSettings as UserSettings).accountsHasAllTracRecord) {
                const customer = this.allTracCustomer as AllTracCustomer;
                if (oidcStore.firstName) {
                    customer.firstName = oidcStore.firstName;
                }
                if (oidcStore.lastName) {
                    customer.lastName = oidcStore.lastName;
                }
                if (oidcStore.email) {
                    customer.email = oidcStore.email;
                }
                if (oidcStore.phoneNumber) {
                    customer.phoneNumber = oidcStore.phoneNumber;
                }

                await this.executeAction(
                    this.saveAllTracCustomer(customer),
                    "accountConnectionCompleteSaveAllTracCustomer"
                );
            }

            const newSettings = oidcStore.cloneUserSettings<UserSettings>();
            newSettings.accountsShowWelcomeDialog = false;
            await oidcStore.updateUserSettings(newSettings);
            this.$emit("currentStep", 1);
            this.$emit("accountConnectionComplete", false);
        }
    }
});

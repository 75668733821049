
/* global google */
import { GoogleMapService } from "@/services";
import { useStoreLocationStore } from "@/stores";
import Vue, { defineComponent, getCurrentInstance, onMounted, onUnmounted, ref, watch } from "vue";

let uidCounter = 0;

export default defineComponent({
    name: "VTextFieldPlaceSearch",
    inheritAttrs: false,
    setup() {
        const uid = uidCounter++;
        const storeLocationStore = useStoreLocationStore();
        const searchInput = ref<string | undefined>(undefined);
        const search = ref<Vue | null>(null);
        const instance = getCurrentInstance();

        watch(() => storeLocationStore.searchQuery, (newValue) => {
            searchInput.value = newValue;
        });


        onMounted(() => {
            const input = search.value?.$el?.querySelector("input");

            if (!input)
                throw Error("Search input not found");

            GoogleMapService.getInstance().addAutocomplete(uid.toString(), input, (place: google.maps.places.PlaceResult) => {
                if (!place?.geometry?.location) {
                    return;
                }

                if (place.formatted_address) {
                    searchInput.value = place.formatted_address;
                } else {
                    searchInput.value = place.name;
                }
                const geometryLocation = place.geometry.location.toJSON();

                storeLocationStore.$patch((state) => {
                    state.searchQuery = searchInput.value;
                    state.searchQueryLocation = geometryLocation;
                });

                // TODO Verify this needs to run on mount or trigger a loader
                void storeLocationStore.searchStoreLocations();

                if (getCurrentInstance()?.proxy.$route.name !== "storeLocations") {
                    return void getCurrentInstance()?.proxy.$router.push({ name: "storeLocations" });
                }
            });
        });

        onUnmounted(() => {
            GoogleMapService.getInstance().removeAutocomplete(uid.toString());
        });

        async function onLocationClick(): Promise<void> {
            await storeLocationStore.getCurrentUserLocation();

            searchInput.value = new google.maps.LatLng(
                storeLocationStore.userLocation?.lat ?? 0,
                storeLocationStore.userLocation?.lng ?? 0)
                .toString();

            storeLocationStore.$patch((state) => {
                state.searchQuery = "";
                state.searchQueryLocation = undefined;
            });

            await storeLocationStore.searchStoreLocations();

            if (getCurrentInstance()?.proxy.$route.name !== "storeLocations") {
                await getCurrentInstance()?.proxy.$router.push({ name: "storeLocations" });
            }
        }

        async function onEnterPress(): Promise<void> {
            await storeLocationStore.findQueryLocation(searchInput.value);

            if (instance?.proxy.$route.name !== "storeLocations") {
                await instance?.proxy.$router.push({ name: "storeLocations" });
            }
        }

        function onClearClick(): void {
            storeLocationStore.$patch((state) => {
                state.searchQuery = "";
                state.searchQueryLocation = undefined;
            });
        }

        return {
            search,
            searchInput,
            onLocationClick,
            onEnterPress,
            onClearClick
        };
    }
});

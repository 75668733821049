import { ChallengeSuccessToken } from "@/models";
import { environment } from "@/utilities";
import { HttpStatusCode, ProblemDetails } from "@points/common";
import axios, { AxiosError } from "axios";

export async function getChallengePrompt(): Promise<string> {
    try {
        const response = await axios.get<string>(`${environment.VUE_APP_CENTRAL}/accounts/api/challenges/me`);
        if (response.status === HttpStatusCode.Ok) {
            return response.data;
        }
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            return "";
        }
    }

    return "Unknown error";
}

export async function postChallengeResponse(answer: Record<string, unknown>): Promise<ChallengeSuccessToken | string | undefined > {
    try {
        const response = await axios.post<ChallengeSuccessToken>(`${environment.VUE_APP_CENTRAL}/accounts/api/challenges/me/responses`, answer);
        return response.data;
    } catch (error: unknown) {
        if (error instanceof Error) {
            const axiosError = error as AxiosError<ProblemDetails | string>;
            if (axiosError.response?.status === HttpStatusCode.BadRequest) {
                return axiosError.response.data as string;
            } else {
                return (axiosError.response?.data as ProblemDetails)?.title;
            }
        }
    }


}

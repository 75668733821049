import { getAllTracCustomerVehicles, postAllTracCustomer } from "@/api";
import { AllTracCustomer, UserVehicle } from "@/models";
import { defineStore } from "pinia";

export const useAlltracCustomerStore = defineStore("alltrac-customer", {
    state: () => ({
        allTracCustomer: undefined as AllTracCustomer | undefined,
        allTracCustomerVehicles: [] as UserVehicle[]
    }),
    actions: {
        async saveAllTracCustomer(allTracCustomer: AllTracCustomer): Promise<void> {
            this.allTracCustomer = await postAllTracCustomer(allTracCustomer);
        },

        async loadAllTracCustomerVehicles(id: number): Promise<void> {
            this.allTracCustomerVehicles = await getAllTracCustomerVehicles(id);
        }
    }
});

import { environment } from "@/utilities";
import axios from "axios";

export async function postVehicleImages(vehicleId: string, images: FileList): Promise<string[]> {
    if (!vehicleId) {
        throw new Error("vehicleId is required");
    }

    const formData = new FormData();
    for (const image of images) {
        formData.append("files", image, image.name);
    }

    const response = await axios.post<string[]>(
        `${environment.VUE_APP_CENTRAL}/accounts/api/users/me/vehicles/${vehicleId}/images`,
        formData
    );

    return response.data;
}

export async function deleteVehicleImage(vehicleId: string, id: string): Promise<void> {
    if (!vehicleId) {
        throw new Error("vehicleId is required");
    }

    if (!id) {
        throw new Error("id is required");
    }

    await axios.delete(`${environment.VUE_APP_CENTRAL}/accounts/api/users/me/vehicles/${vehicleId}/images/${id}`);
}

import { getPromotions } from "@/api";
import { Promotion } from "@/models";
import { defineStore } from "pinia";

export const usePromotionStore = defineStore("promotion", {
    state: () => ({
        promotions: [] as Promotion[]
    }),
    actions: {
        async loadPromotions(storeLocationId?: string): Promise<void> {
            this.promotions = await getPromotions(storeLocationId);
        }
    }
});

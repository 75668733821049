import { getUser, patchUser, putUser } from "@/api";
import { User } from "@/models";
import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
    state: () => ({
        currentUser: undefined as User | undefined
    }),
    actions: {
        async loadCurrentUser(): Promise<void> {
            this.currentUser = await getUser();
        },

        async createUser(): Promise<User> {
            this.currentUser = await putUser();
            return this.currentUser;
        },

        async updateCurrentUser({ newUser, token }: { newUser: User; token: string }): Promise<void> {
            if (!newUser?.id) {
                throw new Error("newUser not found");
            }

            this.currentUser = await patchUser(this.currentUser as User, newUser, token);
        }
    }
});

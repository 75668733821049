
import { useChallengeStore } from "@/stores";
import { mapState } from "pinia";
import { defineComponent } from "vue";
import AccountConnection from "./AccountConnection.vue";
import AccountConnectionComplete from "./AccountConnectionComplete.vue";
import AccountConnectionVehicleImport from "./AccountConnectionVehicleImport.vue";

export default defineComponent({
    name: "WelcomeNewUserDialog",

    components: {
        AccountConnection,
        AccountConnectionComplete,
        AccountConnectionVehicleImport
    },

    props: {
        value: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        currentStep: 1
    }),

    computed: {
        ...mapState(useChallengeStore, ["challengePrompt"]),

        finalStep(): number {
            if (this.challengePrompt) {
                return 3;
            } else {
                return 2;
            }
        },

        showWelcomeNewUserDialog: {
            get(): boolean {
                return this.value;
            },
            set(value: boolean) {
                this.$emit("input", value);
            }
        }
    }
});

import { ServiceRecord } from "@/models";
import { environment } from "@/utilities";
import { ODataQueryOptions, Page } from "@points/common";
import axios from "axios";
import { compare } from "fast-json-patch";

export async function getServiceRecords(queryOptions?: ODataQueryOptions): Promise<ServiceRecord[]> {
    const response = await axios.get<Page<ServiceRecord>>(`${environment.VUE_APP_CENTRAL}/accounts/api/users/me/service-records`, {
        params: queryOptions
    });

    return response.data.items;
}

export async function postServiceRecord(record: ServiceRecord): Promise<ServiceRecord> {
    const response = await axios.post<ServiceRecord>(`${environment.VUE_APP_CENTRAL}/accounts/api/users/me/service-records`, record);
    return response.data;
}

export async function patchServiceRecord(oldRecord: Partial<ServiceRecord>, newRecord: Partial<ServiceRecord>): Promise<ServiceRecord> {
    const patchDocument = compare(oldRecord, newRecord);
    const response = await axios.patch<ServiceRecord>(`${environment.VUE_APP_CENTRAL}/accounts/api/users/me/service-records/${newRecord.id}`, patchDocument);
    return response.data;
}

export async function deleteServiceRecord(id: string): Promise<void> {
    if (!id) {
        throw new Error("id is required");
    }

    await axios.delete(`${environment.VUE_APP_CENTRAL}/accounts/api/users/me/service-records/${id}`);
}

export async function syncServiceRecords(): Promise<void> {
    await axios.post(`${environment.VUE_APP_CENTRAL}/accounts/api/users/me/service-records-sync`);
}

import { deleteVehicle, deleteVehicleImage, getVehicles, patchVehicle, postVehicle, postVehicleImages } from "@/api";
import { UserVehicle } from "@/models";
import { RootState } from "@/store";
import { HalLink } from "@points/common";
import { ActionContext, Module } from "vuex";

interface UserVehicleState {
    vehicles: UserVehicle[];
    currentVehicle: Partial<UserVehicle>;
}

const userVehicleModule: Module<UserVehicleState, RootState> = {
    namespaced: true,

    state: {
        vehicles: [],
        currentVehicle: {}
    },

    mutations: {
        setCurrentVehicle(state: UserVehicleState, vehicle: UserVehicle): void {
            state.currentVehicle = vehicle;
        },

        setVehicles(state: UserVehicleState, vehicles: UserVehicle[]): void {
            state.vehicles = vehicles;
        },

        deleteVehicle(state: UserVehicleState, id: string): void {
            const index = state.vehicles.findIndex((vehicle) => vehicle.id === id);
            if (index > -1) {
                state.vehicles.splice(index, 1);
            }
        },

        deleteVehicleImage(state: UserVehicleState, id: string): void {
            const index = (state.currentVehicle._links?.images as HalLink[]).findIndex((link) => link.href.endsWith(id));
            if (index > -1) {
                (state.currentVehicle._links?.images as HalLink[]).splice(index, 1);
            }
        },

        updateVehicles(state: UserVehicleState, vehicle: UserVehicle): void {
            const index = state.vehicles.findIndex((v) => v.id === state.currentVehicle.id);
            if (index > -1) {
                state.vehicles[index] = vehicle;
            } else {
                state.vehicles.push(vehicle);
            }
        }
    },

    actions: {
        async loadVehicles(context: ActionContext<UserVehicleState, RootState>): Promise<void> {
            const vehicles = await getVehicles();

            vehicles.forEach((vehicle) => {
                if (vehicle._links?.images && !Array.isArray(vehicle._links?.images)) {
                    vehicle._links.images = [vehicle._links.images];
                }
            });

            context.commit("setVehicles", vehicles);
        },

        async saveVehicle(context: ActionContext<UserVehicleState, RootState>, vehicle: UserVehicle): Promise<void> {
            const newVehicle = await postVehicle(vehicle);
            const vehicles = JSON.parse(JSON.stringify(context.state.vehicles)) as UserVehicle[];
            vehicles.push(newVehicle);
            context.commit("setVehicles", vehicles);
        },

        async updateVehicle(context: ActionContext<UserVehicleState, RootState>, vehicle: UserVehicle): Promise<void> {
            if (!vehicle.id) {
                throw new Error("vehicle hasn't been loaded");
            }

            const newVehicle = await patchVehicle(context.state.currentVehicle, vehicle);

            if (newVehicle) {
                if (newVehicle._links?.images && !Array.isArray(newVehicle._links?.images)) {
                    newVehicle._links.images = [newVehicle._links.images];
                }

                context.commit("updateVehicles", newVehicle);
            }
        },

        async deleteVehicle(context: ActionContext<UserVehicleState, RootState>, id: string): Promise<void> {
            await deleteVehicle(id);
            context.commit("deleteVehicle", id);
        },

        async uploadVehicleImages(context: ActionContext<UserVehicleState, RootState>, images: FileList): Promise<string[]> {
            if (!context.state.currentVehicle.id) {
                throw new Error("currentVehicle hasn't been loaded");
            }

            const imageUris = await postVehicleImages(context.state.currentVehicle.id || "", images);
            const imageLinks = imageUris.map((imageUri) => ({ href: imageUri } as HalLink));
            const newVehicle = JSON.parse(JSON.stringify(context.state.currentVehicle)) as UserVehicle;

            if (newVehicle._links?.images) {
                (newVehicle._links.images as HalLink[]).push(...imageLinks);
                context.commit("setCurrentVehicle", newVehicle);
                context.commit("updateVehicles", newVehicle);
            }

            return imageUris;
        },

        async deleteVehicleImage(context: ActionContext<UserVehicleState, RootState>, id: string): Promise<void> {
            if (!context.state.currentVehicle.id) {
                throw new Error("currentVehicle hasn't been loaded");
            }

            await deleteVehicleImage(context.state.currentVehicle.id || "", id);

            const newVehicle = Object.assign({}, context.state.currentVehicle);

            if (newVehicle._links?.images) {
                context.commit("deleteVehicleImage", id);
            }
        }
    }
};

export default userVehicleModule;

import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                error: "#D90B0B"
            },
            dark: {
                error: "#D90B0B"
            }
        }
    }
});

import { UserVehicle } from "@/models";
import { environment } from "@/utilities";
import { Page } from "@points/common";
import axios from "axios";
import { compare } from "fast-json-patch";

export async function getVehicles(): Promise<UserVehicle[]> {
    const response = await axios.get<Page<UserVehicle>>(`${environment.VUE_APP_CENTRAL}/accounts/api/users/me/vehicles`);
    return response.data.items;
}

export async function postVehicle(vehicle: UserVehicle): Promise<UserVehicle> {
    const response = await axios.post<UserVehicle>(`${environment.VUE_APP_CENTRAL}/accounts/api/users/me/vehicles`, vehicle);
    return response.data;
}

export async function patchVehicle(oldVehicle: Partial<UserVehicle>, newVehicle: Partial<UserVehicle>): Promise<UserVehicle> {
    const patchDocument = compare(oldVehicle, newVehicle);
    const response = await axios.patch<UserVehicle>(`${environment.VUE_APP_CENTRAL}/accounts/api/users/me/vehicles/${newVehicle.id}`, patchDocument);
    return response.data;
}

export async function deleteVehicle(id: string): Promise<void> {
    if (!id) {
        throw new Error("id is required");
    }

    await axios.delete(`${environment.VUE_APP_CENTRAL}/accounts/api/users/me/vehicles/${id}`);
}

import { environment } from "@/utilities";
import Router, { RouteConfig, RouterOptions } from "vue-router";
import { authNavigationGuard } from "./auth-navigation-guard";
import { homeNavigationGuard } from "./home-navigation-guard";

const routes: RouteConfig[] = [
    {
        path: "/",
        name: "home",
        component: () => import("@/views/SignIn.vue")
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/notifications",
        name: "notifications",
        component: () => import("@/views/Notifications.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/privacy-policy",
        name: "privacyPolicy",
        component: () => import("@/views/PrivacyPolicy.vue"),
        meta: { requiresAuth: false }
    },
    {
        path: "/service-history",
        name: "serviceHistory",
        component: () => import("@/views/ServiceHistory.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/store-locations",
        name: "storeLocations",
        component: () => import("@/views/StoreLocations.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/terms-and-conditions",
        name: "termsAndConditions",
        component: () => import("@/views/TermsAndConditions.vue"),
        meta: { requiresAuth: false }
    },
    {
        path: "/virtual-garage",
        name: "virtualGarage",
        component: () => import("@/views/VirtualGarage.vue"),
        meta: { requiresAuth: true }
    }
];

const options: RouterOptions = {
    mode: "history",
    base: environment.BASE_URL,
    routes,
    scrollBehavior(_to, _from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
};

export const createRouter = () => {
    const router = new Router(options);
    router.beforeEach(homeNavigationGuard);
    router.beforeEach(authNavigationGuard);

    return router;
};

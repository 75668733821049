import { getAppointments } from "@/api";
import { Appointment, AppointmentStoreService } from "@/models";
import { RootState } from "@/store";
import { ODataQueryOptions } from "@points/common";
import { ActionContext, Module } from "vuex";

interface AppointmentState {
    appointments: Appointment[];
}

const appointmentModule: Module<AppointmentState, RootState> = {
    namespaced: true,

    state: {
        appointments: []
    },

    mutations: {
        setAppointments(state: AppointmentState, appointments: Appointment[]): void {
            state.appointments = appointments;
        }
    },

    actions: {
        async loadAppointments(context: ActionContext<AppointmentState, RootState>, queryOptions?: ODataQueryOptions): Promise<void> {
            const appointments = await getAppointments(queryOptions);
            context.commit("setAppointments", appointments);
        }
    },

    getters: {
        futureAppointments: (state) => state.appointments
            .filter((appointment) => appointment.appointmentDate
                && new Date(appointment.appointmentDate) > new Date()
                && !appointment.cancelDate)
            .flatMap((appointment) => appointment.services?.map<AppointmentStoreService>((service) => ({
                id: appointment.id,
                name: service.name,
                appointmentDate: appointment.appointmentDate,
                cancelDate: appointment.cancelDate,
                checkInDate: appointment.checkInDate,
                checkOutDate: appointment.checkOutDate,
                confirmationDate: appointment.confirmationDate,
                vehicleOdometer: appointment.vehicleOdometer,
                services: appointment.services
            })))
    }
};

export default appointmentModule;


import { defineComponent } from "vue";

export default defineComponent({

    data() {
        return {
            companyEmail: "",
            companyPhone: "+(503) 283-6494",
            companyTradeName: "Point S",
            legalCompanyName: "Tire Factory, Inc.",
            privacyPolicyLink: "https://www.pointstire.com/privacy-policy",
            website: "PointSTire.com, TireFactory.com and My.PointSCentral.com"
        };
    }
});

import { getServiceCategories, getStoreLocationQuery } from "@/api";
import { StoreLocationQueryResult } from "@/models";
import { GoogleMapService } from "@/services";
import { getStoreLocation, StoreLocation } from "@points/common";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useStoreLocationStore = defineStore("store-location", () => {
    /**
     * Current store location
     */
    const currentStoreLocation = ref<StoreLocation | undefined>();
    /**
     * Whether store locations are being searched for
     */
    const searching = ref(false);
    /**
     * Search query text
     */
    const searchQuery = ref<string | undefined>(undefined);
    /**
     * Search query coordinates
     */
    const searchQueryLocation = ref<google.maps.LatLngLiteral | undefined>();
    /**
     * Store location query results
     */
    const storeLocationQueryResults = ref<StoreLocationQueryResult[]>([]);
    /**
     * User coordinates
     */
    const userLocation = ref<google.maps.LatLngLiteral | undefined>();
    /**
     * Distance in meters to filter results on
     * @description Defaults to 20 miles
     * @default 32186.9
     */
    const distanceInMeters = ref(32186.9);
    /**
     * Services categories
     */
    const serviceCategories = ref<string[]>([]);
    /**
     * Selected service categories to filter results on
     */
    const selectedServiceCategories = ref<string[]>([]);

    /**
     * Loads a store location
     * @param storeId The store id
     */
    async function loadStoreLocation(storeId: string): Promise<void> {
        currentStoreLocation.value = undefined;
        currentStoreLocation.value = await getStoreLocation(storeId);
    }

    /**
     * Loads service categories
     */
    async function loadServiceCategories(): Promise<void> {
        serviceCategories.value = (await getServiceCategories()).map((category) => category.name);
    }

    /**
     * Gets the current users location (coordinates) from the browser
     */
    async function getCurrentUserLocation(): Promise<void> {
        if (navigator.geolocation) {
            return new Promise((resolve) => {
                navigator.geolocation.getCurrentPosition((position) => {
                    userLocation.value = { lat: position.coords.latitude, lng: position.coords.longitude };
                    resolve();
                });
            });
        }
    }

    /**
     * Searches store locations
     */
    async function searchStoreLocations(): Promise<void> {
        const sourceLocation = searchQueryLocation.value ||
            userLocation.value ||
            GoogleMapService.portlandCoordinates;

        try {
            searching.value = true;
            storeLocationQueryResults.value = [];
            const results = await getStoreLocationQuery(
                sourceLocation.lat,
                sourceLocation.lng,
                distanceInMeters.value,
                selectedServiceCategories.value);

            results.forEach(async(result: StoreLocationQueryResult) => {
                result.place = await GoogleMapService.getInstance().getPlaceDetails(result.googlePlaceId);
                result.location = result?.place?.geometry?.location?.toJSON() as google.maps.LatLngLiteral;
                storeLocationQueryResults.value.push(result);
            });
        } finally {
            searching.value = false;
        }
    }

    /**
     * Finds query location
     * @param query The query text
     */
    async function findQueryLocation(query: string | undefined): Promise<void> {
        if (!query) {
            return;
        }

        const place = await GoogleMapService.getInstance().findPlaceFromQuery(query);
        if (!place?.geometry?.location) {
            return;
        }

        if (place.formatted_address) {
            searchQuery.value = place.formatted_address;
        } else {
            searchQuery.value = place.name;
        }

        const geometryLocation = place.geometry.location.toJSON();
        searchQueryLocation.value = geometryLocation;
        await searchStoreLocations();
    }

    return {
        currentStoreLocation,
        searching,
        searchQuery,
        searchQueryLocation,
        storeLocationQueryResults,
        userLocation,
        distanceInMeters,
        serviceCategories,
        selectedServiceCategories,
        loadStoreLocation,
        loadServiceCategories,
        getCurrentUserLocation,
        searchStoreLocations,
        findQueryLocation
    };
});

import { getChallengePrompt, postChallengeResponse } from "@/api";
import { ChallengeSuccessToken } from "@/models";
import { defineStore } from "pinia";

export const useChallengeStore = defineStore("challenge", {
    state: () => ({
        challengeError: "",
        challengePrompt: "",
        challengeSuccessToken: undefined as ChallengeSuccessToken | undefined
    }),
    actions: {
        async loadChallengePrompt(): Promise<void> {
            this.challengePrompt = await getChallengePrompt();
        },

        async loadChallengePromptResponse(answer: Record<string, unknown>): Promise<void> {
            const response = await postChallengeResponse(answer);
            if (typeof response === "object") {
                this.challengeSuccessToken = response;
            } else if (typeof response === "string") {
                this.challengeError = response;
            }
        }
    }
});

import { Vehicle, VehicleMake, VehicleModel } from "@/models";
import { ODataQueryOptions, Page } from "@points/common";
import axios from "axios";

export async function getVehicleYears(oDataQueryOptions: ODataQueryOptions): Promise<number[]> {
    // TODO: Temporary interface until api returns 'year'
    interface Year {
        vehicleYearId?: number;
    }

    const response = await axios.get<Page<Year>>("v1/VehicleYear", {
        params: oDataQueryOptions
    });

    return response.data.items.map((year) => year.vehicleYearId || 0);
}

export async function getVehicleMakes(year: number): Promise<string[]> {
    if (!year) {
        throw new Error("year is required");
    }

    const response = await axios.get<VehicleMake[]>("v1/VehicleMake", {
        params: {
            vehicleYearId: year
        }
    });

    return response.data.map((make) => make.make || "");
}

export async function getVehicleModels(year: number, make: string): Promise<string[]> {
    if (!year) {
        throw new Error("year is required");
    }
    if (!make) {
        throw new Error("make is required");
    }

    const response = await axios.get<VehicleModel[]>("v1/VehicleModel", {
        params: {
            vehicleYearId: year,
            vehicleMake: make
        }
    });

    return response.data.map((model) => model.model || "");
}

export async function getVehicleSubmodels(year: number, make: string, model: string): Promise<string[]> {
    if (!year) {
        throw new Error("year is required");
    }
    if (!make) {
        throw new Error("make is required");
    }
    if (!model) {
        throw new Error("model is required");
    }

    // TODO: Temporary interface until api returns 'submodel'
    interface Submodel {
        submodel?: string;
    }

    const response = await axios.get<Submodel[]>("v1/VehicleSubModel", {
        params: {
            vehicleYearId: year,
            vehicleMake: make,
            vehicleModel: model
        }
    });

    return response.data.map((vehicle) => vehicle.submodel || "");
}

export async function getVehicleInformation(oDataQueryOptions: ODataQueryOptions): Promise<Vehicle[]> {
    const response = await axios.get<Page<Vehicle>>("v1/VehicleInformation", {
        params: oDataQueryOptions
    });

    return response.data.items;
}

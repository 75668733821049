import { Appointment } from "@/models";
import { environment } from "@/utilities";
import { ODataQueryOptions, Page } from "@points/common";
import axios from "axios";

export async function getAppointments(queryOptions?: ODataQueryOptions): Promise<Appointment[]> {
    const response = await axios.get<Page<Appointment>>(`${environment.VUE_APP_CENTRAL}/accounts/api/users/me/appointments`, {
        params: queryOptions
    });
    return response.data.items;
}

import { deleteServiceRecord, deleteServiceRecordDocument, getServiceRecordDocument, getServiceRecords, patchServiceRecord, patchServiceRecordDocument, postServiceRecord, postServiceRecordDocuments } from "@/api";
import { ServiceRecord, ServiceRecordDocument } from "@/models";
import { RootState } from "@/store";
import { ODataQueryOptions } from "@points/common";
import { ActionContext, Module } from "vuex";

interface ServiceRecordState {
    serviceRecords: ServiceRecord[];
    currentServiceRecord: Partial<ServiceRecord>;
    serviceRecordDocuments: ServiceRecordDocument[];
    currentServiceRecordDocument: Partial<ServiceRecordDocument>;
}

const serviceRecordModule: Module<ServiceRecordState, RootState> = {
    namespaced: true,

    state: {
        serviceRecords: [],
        currentServiceRecord: {},
        serviceRecordDocuments: [],
        currentServiceRecordDocument: {}
    },

    mutations: {
        setCurrentServiceRecord(state: ServiceRecordState, record: ServiceRecord): void {
            state.currentServiceRecord = record;
        },

        setServiceRecords(state: ServiceRecordState, records: ServiceRecord[]): void {
            state.serviceRecords = records;
            state.serviceRecords.sort((a, b) => Date.parse(b.serviceDate || "") - Date.parse(a.serviceDate || ""));
        },

        deleteServiceRecord(state: ServiceRecordState, id: string): void {
            const index = state.serviceRecords.findIndex((record) => record.id === id);
            if (index > -1) {
                state.serviceRecords.splice(index, 1);
            }
        },

        updateServiceRecords(state: ServiceRecordState, record: ServiceRecord): void {
            const index = state.serviceRecords.findIndex((r) => r.id === state.currentServiceRecord.id);
            if (index > -1) {
                state.serviceRecords.splice(index, 1, record);
            } else {
                state.serviceRecords.push(record);
            }

            state.serviceRecords.sort((a, b) => Date.parse(b.serviceDate || "") - Date.parse(a.serviceDate || ""));
            (state.currentServiceRecord.serviceRecordDocuments as ServiceRecordDocument[])
                .sort((a, b) => Date.parse(b.created || "") - Date.parse(a.created || ""));
        },

        setCurrentServiceRecordDocument(state: ServiceRecordState, document: ServiceRecordDocument): void {
            state.currentServiceRecordDocument = document;
        },

        deleteServiceRecordDocument(state: ServiceRecordState, id: string): void {
            const index = (state.currentServiceRecord.serviceRecordDocuments as ServiceRecordDocument[])
                .findIndex((document) => document.id === id);
            if (index > -1) {
                (state.currentServiceRecord.serviceRecordDocuments as ServiceRecordDocument[]).splice(index, 1);
            }
        },

        updateServiceRecordDocuments(state: ServiceRecordState, document: ServiceRecordDocument): void {
            const recordIndex = state.serviceRecords.findIndex((record) => record.id === state.currentServiceRecord.id);
            const documentIndex = (state.currentServiceRecord.serviceRecordDocuments as ServiceRecordDocument[])
                .findIndex((document) => document.id === state.currentServiceRecordDocument.id);
            if (recordIndex > -1 || documentIndex > -1) {
                state.serviceRecords[recordIndex].serviceRecordDocuments?.splice(documentIndex, 1, document);
            } else {
                state.serviceRecords[recordIndex].serviceRecordDocuments?.push(document);
            }
        }
    },

    actions: {
        async loadServiceRecords(context: ActionContext<ServiceRecordState, RootState>, queryOptions?: ODataQueryOptions): Promise<ServiceRecord[]> {
            const records = await getServiceRecords(queryOptions);
            context.commit("setServiceRecords", records);
            return records;
        },

        async saveServiceRecord(context: ActionContext<ServiceRecordState, RootState>, record: ServiceRecord): Promise<ServiceRecord> {
            record.locationName = "Self-Service";
            const newRecord = await postServiceRecord(record);
            const records = JSON.parse(JSON.stringify(context.state.serviceRecords)) as ServiceRecord[];
            records.push(newRecord);
            context.commit("setServiceRecords", records);
            return newRecord;
        },

        async updateServiceRecord(context: ActionContext<ServiceRecordState, RootState>, record: ServiceRecord): Promise<ServiceRecord> {
            if (!record.id) {
                throw new Error("service record hasn't been loaded");
            }

            const newRecord = await patchServiceRecord(context.state.currentServiceRecord, record);
            context.commit("updateServiceRecords", newRecord);
            return newRecord;
        },

        async deleteServiceRecord(context: ActionContext<ServiceRecordState, RootState>, id: string): Promise<void> {
            await deleteServiceRecord(id);
            context.commit("deleteServiceRecord", id);
        },

        async loadServiceRecordDocument(context: ActionContext<ServiceRecordState, RootState>, id: string): Promise<ServiceRecordDocument> {
            if (!context.state.currentServiceRecord.id) {
                throw new Error("currentServiceRecord hasn't been loaded");
            }

            const serviceRecordDocument = await getServiceRecordDocument(context.state.currentServiceRecord.id, id || "");
            context.commit("setCurrentServiceRecordDocument", serviceRecordDocument);
            return serviceRecordDocument;
        },

        async uploadServiceRecordDocuments(context: ActionContext<ServiceRecordState, RootState>, serviceRecordDocuments: FileList): Promise<ServiceRecordDocument[]> {
            if (!context.state.currentServiceRecord.id) {
                throw new Error("currentServiceRecord hasn't been loaded");
            }

            const documents = await postServiceRecordDocuments(context.state.currentServiceRecord.id || "", serviceRecordDocuments);
            const newRecord = JSON.parse(JSON.stringify(context.state.currentServiceRecord)) as ServiceRecord;

            if (newRecord.serviceRecordDocuments) {
                newRecord.serviceRecordDocuments.push(...documents);
                context.commit("setCurrentServiceRecord", newRecord);
                context.commit("updateServiceRecords", newRecord);
            }

            return documents;
        },

        async updateServiceRecordDocument(context: ActionContext<ServiceRecordState, RootState>, document: ServiceRecordDocument): Promise<ServiceRecordDocument> {
            if (!context.state.currentServiceRecord.id) {
                throw new Error("currentServiceRecord hasn't been loaded");
            }

            const newDocument = await patchServiceRecordDocument(context.state.currentServiceRecord.id, context.state.currentServiceRecordDocument, document);
            context.commit("setCurrentServiceRecordDocument", newDocument);
            context.commit("updateServiceRecordDocuments", newDocument);
            return newDocument;
        },

        async deleteServiceRecordDocument(context: ActionContext<ServiceRecordState, RootState>, id: string): Promise<void> {
            if (!context.state.currentServiceRecord.id) {
                throw new Error("currentServiceRecord hasn't been loaded");
            }

            await deleteServiceRecordDocument(context.state.currentServiceRecord.id || "", id);
            const newRecord = Object.assign({}, context.state.currentServiceRecord);
            if (newRecord.serviceRecordDocuments) {
                context.commit("deleteServiceRecordDocument", context.state.currentServiceRecordDocument.id);
            }
        }
    },

    getters: {
        pastServiceRecords: (state) => state.serviceRecords
            .filter((record) => record.serviceDate && record.serviceDate < new Date().toDateString())
    }
};

export default serviceRecordModule;

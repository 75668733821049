/**
 * Converts meters to miles
 * @param meters The meters
 * @returns The converted miles
 */
export function convertMetersToMiles(meters: number): number {
    return meters / 1609.34;
}

/**
 * Converts miles to meters
 * @param miles The miles
 * @returns The converted meters
 */
export function convertMilesToMeters(miles: number): number {
    return miles * 1609.34;
}

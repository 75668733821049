import { getChallengePrompt, postChallengeResponse } from "@/api";
import { ChallengeSuccessToken } from "@/models";
import { RootState } from "@/store";
import { ActionContext, Module } from "vuex";

interface ChallengeState {
    challengeError: string;
    challengePrompt: string;
    challengeSuccessToken: ChallengeSuccessToken | undefined;
}

const challengeModule: Module<ChallengeState, RootState> = {
    namespaced: true,

    state: {
        challengeError: "",
        challengePrompt: "",
        challengeSuccessToken: undefined
    },

    mutations: {
        setChallengeError(state: ChallengeState, error: string): void {
            state.challengeError = error;
        },

        setChallengePrompt(state: ChallengeState, challenge: string): void {
            state.challengePrompt = challenge;
        },

        setChallengeSuccessToken(state: ChallengeState, successToken: ChallengeSuccessToken): void {
            state.challengeSuccessToken = successToken;
        }
    },

    actions: {
        async loadChallengePrompt(context: ActionContext<ChallengeState, RootState>): Promise<void> {
            const challengePrompt = await getChallengePrompt();
            context.commit("setChallengePrompt", challengePrompt);
        },

        async loadChallengePromptResponse(context: ActionContext<ChallengeState, RootState>, answer: Record<string, unknown>): Promise<void> {
            const response = await postChallengeResponse(answer);
            if (typeof response === "object") {
                context.commit("setChallengeSuccessToken", response);
            } else if (typeof response === "string") {
                context.commit("setChallengeError", response);
            }
        }
    }
};

export default challengeModule;

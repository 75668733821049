import { getAppointments } from "@/api";
import { Appointment, AppointmentStoreService } from "@/models";
import { ODataQueryOptions } from "@points/common";
import { defineStore } from "pinia";

export const useAppointmentStore = defineStore("appointment", {
    state: () => ({
        appointments: [] as Appointment[]
    }),
    getters: {
        futureAppointments: (state) => state.appointments
            .filter((appointment) => appointment.appointmentDate
                && new Date(appointment.appointmentDate) > new Date()
                && !appointment.cancelDate)
            .flatMap((appointment) => appointment.services?.map<AppointmentStoreService>((service) => ({
                id: appointment.id,
                name: service.name,
                appointmentDate: appointment.appointmentDate,
                cancelDate: appointment.cancelDate,
                checkInDate: appointment.checkInDate,
                checkOutDate: appointment.checkOutDate,
                confirmationDate: appointment.confirmationDate,
                vehicleOdometer: appointment.vehicleOdometer,
                services: appointment.services
            })))
    },
    actions: {
        async loadAppointments(queryOptions?: ODataQueryOptions): Promise<void> {
            const appointments = await getAppointments(queryOptions);
            this.appointments = appointments;
        }
    }
});

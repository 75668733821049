import { StoreLocationQueryResult } from "@/models";
import { environment } from "@/utilities";
import axios from "axios";

/**
 * Searches nearby store locations
 * @param latitude The source latitude
 * @param longitude The source longitude
 * @param distance The max distance to search in meters
 * @param serviceCategories Specifies service categories to filter on
 * @returns Nearby store locations
 */
export async function getStoreLocationQuery(
    latitude: number,
    longitude: number,
    distance: number,
    serviceCategories?: string[]): Promise<StoreLocationQueryResult[]> {
    const response = await axios.get<StoreLocationQueryResult[]>(`${environment.VUE_APP_CENTRAL}/alltrac/api/store-location-queries`, {
        params: {
            latitude,
            longitude,
            distance,
            serviceCategories
        }
    });

    return response.data;
}

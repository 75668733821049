import { User } from "@/models";
import { environment } from "@/utilities";
import axios from "axios";
import { compare } from "fast-json-patch";

export async function getUser(): Promise<User> {
    const response = await axios.get<User>(`${environment.VUE_APP_CENTRAL}/accounts/api/users/me`);
    return response.data;
}

export async function putUser(): Promise<User> {
    const response = await axios.put<User>(`${environment.VUE_APP_CENTRAL}/accounts/api/users/me`);
    return response.data;
}

export async function patchUser(oldUser: User, newUser: User, token?: string): Promise<User> {
    const patchDocument = compare(oldUser, newUser);
    const response = await axios.patch<User>(
        `${environment.VUE_APP_CENTRAL}/accounts/api/users/me`,
        patchDocument, {
            headers: {
                "Challenge-Success-Token": token ?? ""
            }
        });
    return response.data;
}

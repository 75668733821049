
import { UserSettings } from "@/models";
import { useAlltracCustomerStore, useChallengeStore } from "@/stores";
import { useActionStore, useOidcStore } from "@points/common";
import { mapActions, mapState, mapWritableState } from "pinia";
import { defineComponent, toRefs } from "vue";

export default defineComponent({
    name: "AccountConnection",
    setup() {
        const {
            email,
            phoneNumber,
            phoneNumberVerified
        } = toRefs(useOidcStore());

        return {
            email,
            phoneNumber,
            phoneNumberVerified
        };
    },
    data: () => ({
        challengeResponse: {
            challengePromptAnswer: ""
        }
    }),

    computed: {
        ...mapState(useAlltracCustomerStore, ["allTracCustomerVehicles"]),
        ...mapWritableState(useChallengeStore, ["challengeError", "challengePrompt", "challengeSuccessToken"]),

        challengePromptLength(): number {
            if (this.challengePrompt) {
                return this.challengePrompt.length;
            } else {
                return 0;
            }
        }
    },

    async mounted(): Promise<void> {
        await this.executeAction(
            this.loadChallengePrompt(),
            "accountConnectionLoadChallengePrompt"
        );
    },

    methods: {
        ...mapActions(useActionStore, ["executeAction", "isActionPending"]),
        ...mapActions(useAlltracCustomerStore, ["loadAllTracCustomerVehicles"]),
        ...mapActions(useChallengeStore, ["loadChallengePrompt", "loadChallengePromptResponse"]),

        continueToAccountCreation(): void {
            this.challengeError = "";
            this.challengePrompt = "";
            this.challengeSuccessToken = undefined;
            this.$emit("currentStep", 3);
        },

        async continueWithNewAccount(): Promise<void> {
            const oidcStore = useOidcStore();
            const newSetting = oidcStore.cloneUserSettings<UserSettings>();
            newSetting.accountsHasAllTracRecord = true;
            await oidcStore.updateUserSettings(newSetting);
            this.$emit("currentStep", 2);
        },

        async continueWithoutNewAccount(): Promise<void> {
            const oidcStore = useOidcStore();
            const newSetting = oidcStore.cloneUserSettings<UserSettings>();
            newSetting.accountsHasAllTracRecord = false;
            await oidcStore.updateUserSettings(newSetting);
            this.$emit("currentStep", 2);
        },

        async submitChallengeResponse(): Promise<void> {
            const oidcStore = useOidcStore();

            await this.executeAction(
                this.loadChallengePromptResponse(this.challengeResponse),
                "accountConnectionLoadChallengePromptResponse"
            );

            if (this.challengeSuccessToken?.customerId) {
                await this.executeAction(
                    this.loadAllTracCustomerVehicles(this.challengeSuccessToken.customerId),
                    "accountConnectionLoadAllTracCustomerVehicles"
                );

                if (this.allTracCustomerVehicles.length) {
                    this.$emit("currentStep", 2);
                } else {
                    this.$emit("currentStep", 3);
                }

                const newSetting = oidcStore.cloneUserSettings<UserSettings>();

                newSetting.accountsHasAllTracRecord = true;
                await oidcStore.updateUserSettings(newSetting);
            }
        }
    }
});

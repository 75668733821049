import { Promotion } from "@/models";
import { environment } from "@/utilities";
import axios from "axios";

export async function getPromotions(storeLocationId?: string): Promise<Promotion[]> {
    const response = await axios.get<Promotion[]>(`${environment.VUE_APP_CENTRAL}/marketing/api/promotions`, {
        params: {
            StoreId: storeLocationId
        }
    });
    return response.data;
}

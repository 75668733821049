
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
        PrivacyPolicy
    },
    props: {
        showDialog: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        dialog: {
            get(): boolean {
                return this.showDialog;
            },
            set(newValue: boolean) {
                this.$emit("update:showDialog", newValue);
            }
        }
    }

});

import { ServiceRecordDocument } from "@/models";
import { environment } from "@/utilities";
import axios from "axios";
import { compare } from "fast-json-patch";

export async function getServiceRecordDocument(serviceRecordId: string, id: string): Promise<ServiceRecordDocument> {
    if (!serviceRecordId) {
        throw new Error("serviceRecordId is required");
    }

    if (!id) {
        throw new Error("id is required");
    }

    const response = await axios.get<ServiceRecordDocument>(`${environment.VUE_APP_CENTRAL}/accounts/api/users/me/service-records/${serviceRecordId}/documents/${id}`);
    return response.data;
}

export async function postServiceRecordDocuments(serviceRecordId: string, documents: FileList): Promise<ServiceRecordDocument[]> {
    if (!serviceRecordId) {
        throw new Error("serviceRecordId is required");
    }

    const formData = new FormData();
    for (const document of documents) {
        formData.append("files", document, document.name);
    }

    const response = await axios.post<ServiceRecordDocument[]>(
        `${environment.VUE_APP_CENTRAL}/accounts/api/users/me/service-records/${serviceRecordId}/documents`,
        formData
    );

    return response.data;
}

export async function patchServiceRecordDocument(serviceRecordId: string, oldDocument: Partial<ServiceRecordDocument>, newDocument: Partial<ServiceRecordDocument>): Promise<ServiceRecordDocument> {
    const patchDocument = compare(oldDocument, newDocument);
    const response = await axios.patch<ServiceRecordDocument>(`${environment.VUE_APP_CENTRAL}/accounts/api/users/me/service-records/${serviceRecordId}/documents/${newDocument.id}`, patchDocument);
    return response.data;
}

export async function deleteServiceRecordDocument(serviceRecordId: string, id: string): Promise<void> {
    if (!serviceRecordId) {
        throw new Error("serviceRecordId is required");
    }

    if (!id) {
        throw new Error("id is required");
    }

    await axios.delete(`${environment.VUE_APP_CENTRAL}/accounts/api/users/me/service-records/${serviceRecordId}/documents/${id}`);
}

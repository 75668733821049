import { getUser, patchUser, putUser } from "@/api";
import { User } from "@/models";
import { environment } from "@/utilities";
import Vue from "vue";
import Vuex, { ActionContext } from "vuex";
import allTracCustomer from "./modules/alltrac-customer";
import appointment from "./modules/appointment";
import challenge from "./modules/challenge";
import promotion from "./modules/promotion";
import serviceRecord from "./modules/service-record";
import userVehicle from "./modules/user-vehicle";

Vue.use(Vuex);

export * from "../stores/store-location";

export interface RootState {
    currentUser: User;
}

export default new Vuex.Store<RootState>({
    strict: environment.NODE_ENV !== "production",

    modules: {
        allTracCustomer,
        appointment,
        challenge,
        promotion,
        serviceRecord,
        userVehicle
    },

    state: {
        currentUser: {}
    },

    mutations: {
        setCurrentUser(state: RootState, user: User): void {
            state.currentUser = user;
        }
    },

    actions: {
        async loadCurrentUser(context: ActionContext<RootState, RootState>): Promise<User> {
            const user = await getUser();
            context.commit("setCurrentUser", user);
            return user;
        },

        async createUser(context: ActionContext<RootState, RootState>): Promise<User> {
            const user = await putUser();
            context.commit("setCurrentUser", user);
            return user;
        },

        async updateCurrentUser(context: ActionContext<RootState, RootState>, { newUser, token }: { newUser: User; token: string }): Promise<void> {
            if (!newUser?.id) {
                throw new Error("newUser not found");
            }
            const user = await patchUser(context.state.currentUser, newUser, token);
            context.commit("setCurrentUser", user);
        }
    }
});

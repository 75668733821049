interface Marker {
    marker: google.maps.Marker;
    listeners: google.maps.MapsEventListener[];
}

interface Autocomplete {
    autocomplete: google.maps.places.Autocomplete;
    listeners: google.maps.MapsEventListener[];
}

const placesById: Record<string, google.maps.places.PlaceResult | null> = {};
const placesByQuery: Record<string, google.maps.places.PlaceResult | null> = {};

/**
* Service for google maps apis
*/
export class GoogleMapService {
    static portlandCoordinates = {
        lat: 45.5051064,
        lng: -122.6750261
    } as google.maps.LatLngLiteral;

    private static instance: GoogleMapService;

    private readonly markers: Record<string, Marker> = {};

    private readonly autocompletes: Record<string, Autocomplete> = {};

    private constructor(public map: google.maps.Map) {}

    /**
    * Gets singleton instance of GoogleMapService
    */
    static getInstance(): GoogleMapService {
        if (!GoogleMapService.instance) {
            GoogleMapService.instance = new GoogleMapService(new google.maps.Map(document.createElement("div")));
        }

        return GoogleMapService.instance;
    }

    /**
    * Sets center of map to coordinates
    * @param coordinates map center coordinates
    */
    setCenter(coordinates: google.maps.LatLngLiteral): void {
        if (!coordinates) {
            throw new Error("coordinates are required");
        }

        this.map.setCenter(coordinates);
    }

    /**
    * Gets a marker
    * @param key unique key
    */
    getMarker(key: string): google.maps.Marker | undefined {
        if (!key) {
            throw new Error("key is required");
        }

        return this.markers[key].marker;
    }

    /**
    * Adds a marker
    * @param key unique key
    * @param options marker options
    * @param clickHandler handles marker clicked event
    */
    addMarker(key: string, options: google.maps.MarkerOptions, clickHandler: (marker: google.maps.Marker) => void): google.maps.Marker {
        if (!key) {
            throw new Error("key is required");
        }
        if (!options) {
            throw new Error("options are required");
        }
        if (!clickHandler) {
            throw new Error("clickHandler is required");
        }

        const marker = new google.maps.Marker({ ...options, map: this.map });
        const clickListener = marker.addListener("click", () => {
            clickHandler(marker);
        });
        this.markers[key] = { marker, listeners: [clickListener] };

        return marker;
    }

    /**
    * Removes a marker and cleans up resources
    * @param key unique key
    */
    removeMarker(key: string): void {
        if (!key) {
            throw new Error("key is required");
        }

        const marker = this.markers[key];
        if (!marker) {
            throw new Error("marker not found");
        }

        marker.listeners.forEach((listener) => {
            listener.remove();
        });
        marker.marker.setMap(null);
        delete this.markers[key];
    }

    /**
    * Adds autocomplete to input field
    * @param inputField input field
    * @param handler handles autocomplete selection event
    */
    addAutocomplete(key: string, inputField: HTMLInputElement, handler: (place: google.maps.places.PlaceResult) => void): void {
        if (!key) {
            throw new Error("key is required");
        }
        if (!inputField) {
            throw new Error("inputField is required");
        }
        if (!handler) {
            throw new Error("handler is required");
        }

        const autocomplete = new google.maps.places.Autocomplete(inputField);
        autocomplete.setFields([
            "formatted_address",
            "geometry",
            "name"
        ]);
        const placeChangedListener = autocomplete.addListener("place_changed", () => {
            handler(autocomplete.getPlace());
        });
        this.autocompletes[key] = { autocomplete, listeners: [placeChangedListener] };
    }

    /**
    * Removes an autocomplete and cleansup resources
    * @param key unique key
    */
    removeAutocomplete(key: string): void {
        if (!key) {
            throw new Error("key is required");
        }

        const autocomplete = this.autocompletes[key];
        if (!autocomplete) {
            throw new Error("autocomplete not found");
        }

        autocomplete.listeners.forEach((listener) => {
            listener.remove();
        });
        delete this.autocompletes[key];
    }

    /**
    * Gets place details
    * @param placeId geocode of place
    * @returns found place
    */
    getPlaceDetails(placeId: string): Promise<google.maps.places.PlaceResult> {
        if (!placeId) {
            throw new Error("placeId is required");
        }

        const place = placesById[placeId];
        if (place) {
            return new Promise((resolve) => resolve(place));
        }

        const request = {
            placeId: placeId,
            fields: [
                "address_components",
                "formatted_address",
                "formatted_phone_number",
                "geometry",
                "name",
                "opening_hours.periods",
                "utc_offset_minutes",
                "photos",
                "place_id",
                "rating",
                "user_ratings_total",
                "reviews",
                "url",
                "website"
            ]
        } as google.maps.places.PlaceDetailsRequest;
        const service = new google.maps.places.PlacesService(this.map);

        return new Promise((resolve, reject) => {
            service.getDetails(
                request,
                (
                    result: google.maps.places.PlaceResult | null,
                    status: google.maps.places.PlacesServiceStatus
                ) => {
                    if (result && status === google.maps.places.PlacesServiceStatus.OK) {
                        placesById[placeId] = result;
                        resolve(result);
                    } else {
                        reject(new Error("Failed to find place"));
                    }
                });
        });
    }

    /**
    * Finds place from a query
    * @param query query string
    * @returns found place
    */
    findPlaceFromQuery(query: string): Promise<google.maps.places.PlaceResult> {
        if (!query) {
            throw new Error("query is required");
        }

        const place = placesByQuery[query];
        if (place) {
            return new Promise((resolve) => resolve(place));
        }

        const request = {
            query: query,
            fields: ["formatted_address", "name", "geometry"]
        } as google.maps.places.FindPlaceFromQueryRequest;

        const service = new google.maps.places.PlacesService(this.map);

        return new Promise((resolve, reject) => {
            service.findPlaceFromQuery(
                request,
                (
                    results: google.maps.places.PlaceResult[] | null,
                    status: google.maps.places.PlacesServiceStatus
                ) => {
                    if (results && status === google.maps.places.PlacesServiceStatus.OK) {
                        placesByQuery[query] = results[0];
                        resolve(results[0]);
                    } else {
                        reject(new Error("Failed to find coordinates"));
                    }
                });
        });
    }
}

import { getPromotions } from "@/api";
import { Promotion } from "@/models";
import { RootState } from "@/store";
import { ActionContext, Module } from "vuex";

interface PromotionState {
    promotions: Promotion[];
}

const promotionModule: Module<PromotionState, RootState> = {
    namespaced: true,

    state: {
        promotions: []
    },

    mutations: {
        setPromotions(state: PromotionState, promotions: Promotion[]): void {
            state.promotions = promotions;
        }
    },

    actions: {
        async loadPromotions(context: ActionContext<PromotionState, RootState>, storeLocationId: string): Promise<void> {
            const promotions = await getPromotions(storeLocationId);
            context.commit("setPromotions", promotions);
        }
    }
};

export default promotionModule;

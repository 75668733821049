import { getAllTracCustomerVehicles, postAllTracCustomer } from "@/api";
import { AllTracCustomer, UserVehicle } from "@/models";
import { RootState } from "@/store";
import { ActionContext, Module } from "vuex";

interface AllTracCustomerState {
    allTracCustomer: AllTracCustomer;
    allTracCustomerVehicles: UserVehicle[];
}

const allTracCustomerModule: Module<AllTracCustomerState, RootState> = {
    namespaced: true,

    state: {
        allTracCustomer: {},
        allTracCustomerVehicles: []
    },

    mutations: {
        setAllTracCustomer(state: AllTracCustomerState, customer: AllTracCustomer): void {
            state.allTracCustomer = customer;
        },

        setAllTracCustomerVehicles(state: AllTracCustomerState, vehicles: UserVehicle[]): void {
            state.allTracCustomerVehicles = vehicles;
        }
    },

    actions: {
        async saveAllTracCustomer(context: ActionContext<AllTracCustomerState, RootState>, allTracCustomer: AllTracCustomer): Promise<void> {
            const customer = await postAllTracCustomer(allTracCustomer);
            context.commit("setAllTracCustomer", customer);
        },

        async loadAllTracCustomerVehicles(context: ActionContext<AllTracCustomerState, RootState>, id: number): Promise<void> {
            const vehicles = await getAllTracCustomerVehicles(id);
            context.commit("setAllTracCustomerVehicles", vehicles);
        }
    },

    getters: {
        getAllTracCustomerVehicles: (state) => state.allTracCustomerVehicles
    }
};

export default allTracCustomerModule;

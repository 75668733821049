import { deleteServiceRecord, deleteServiceRecordDocument, getServiceRecordDocument, getServiceRecords, patchServiceRecord, patchServiceRecordDocument, postServiceRecord, postServiceRecordDocuments } from "@/api";
import { ServiceRecord, ServiceRecordDocument } from "@/models";
import { ODataQueryOptions } from "@points/common";
import { defineStore } from "pinia";

export const useServiceRecordStore = defineStore("service-record", {
    state: () => ({
        serviceRecords: [] as ServiceRecord[],
        currentServiceRecord: {} as Partial<ServiceRecord>,
        serviceRecordDocuments: [] as ServiceRecordDocument[],
        currentServiceRecordDocument: {} as Partial<ServiceRecordDocument>
    }),
    getters: {
        pastServiceRecords: (state) => state.serviceRecords
            .filter((record) => record.serviceDate && record.serviceDate < new Date().toDateString())
    },
    actions: {
        async loadServiceRecords(queryOptions?: ODataQueryOptions): Promise<void> {
            this.serviceRecords = await getServiceRecords(queryOptions);
            this.serviceRecords.sort((a, b) => Date.parse(b.serviceDate || "") - Date.parse(a.serviceDate || ""));
        },

        async saveServiceRecord(record: ServiceRecord): Promise<ServiceRecord> {
            record.locationName = "Self-Service";
            const newRecord = await postServiceRecord(record);
            const records = JSON.parse(JSON.stringify(this.serviceRecords)) as ServiceRecord[];
            records.push(newRecord);
            this.serviceRecords = records;
            this.serviceRecords.sort((a, b) => Date.parse(b.serviceDate || "") - Date.parse(a.serviceDate || ""));
            return newRecord;
        },

        async updateServiceRecord(record: ServiceRecord): Promise<ServiceRecord> {
            if (!record.id) {
                throw new Error("service record hasn't been loaded");
            }

            const newRecord = await patchServiceRecord(this.currentServiceRecord as Partial<ServiceRecord>, record);
            const index = this.serviceRecords.findIndex((r) => r.id === this.currentServiceRecord?.id);
            if (index > -1) {
                this.serviceRecords.splice(index, 1, record);
            } else {
                this.serviceRecords.push(record);
            }

            this.serviceRecords.sort((a, b) => Date.parse(b.serviceDate || "") - Date.parse(a.serviceDate || ""));
            (this.currentServiceRecord?.serviceRecordDocuments as ServiceRecordDocument[])
                .sort((a, b) => Date.parse(b.created || "") - Date.parse(a.created || ""));

            return newRecord;
        },

        async deleteServiceRecord(id: string): Promise<void> {
            await deleteServiceRecord(id);
            const index = this.serviceRecords.findIndex((record) => record.id === id);
            if (index > -1) {
                this.serviceRecords.splice(index, 1);
            }
        },

        async loadServiceRecordDocument(id: string): Promise<void> {
            if (!this.currentServiceRecord?.id) {
                throw new Error("currentServiceRecord hasn't been loaded");
            }

            this.currentServiceRecordDocument = await getServiceRecordDocument(this.currentServiceRecord.id, id || "");
        },

        async uploadServiceRecordDocuments(serviceRecordDocuments: FileList): Promise<ServiceRecordDocument[]> {
            if (!this.currentServiceRecord?.id) {
                throw new Error("currentServiceRecord hasn't been loaded");
            }

            const documents = await postServiceRecordDocuments(this.currentServiceRecord.id || "", serviceRecordDocuments);
            const newRecord = JSON.parse(JSON.stringify(this.currentServiceRecord)) as Partial<ServiceRecord>;

            if (newRecord.serviceRecordDocuments !== undefined) {
                newRecord.serviceRecordDocuments.push(...documents);
                this.currentServiceRecord = newRecord;

                // Update service records
                const index = this.serviceRecords.findIndex((r) => r.id === this.currentServiceRecord?.id);
                if (index > -1) {
                    this.serviceRecords.splice(index, 1, newRecord as ServiceRecord);
                } else {
                    this.serviceRecords.push(newRecord as ServiceRecord);
                }

                this.serviceRecords.sort((a, b) => Date.parse(b.serviceDate || "") - Date.parse(a.serviceDate || ""));
                (this.currentServiceRecord.serviceRecordDocuments as ServiceRecordDocument[])
                    .sort((a, b) => Date.parse(b.created || "") - Date.parse(a.created || ""));
            }

            return documents;
        },

        async updateServiceRecordDocument(document: ServiceRecordDocument): Promise<ServiceRecordDocument> {
            if (!this.currentServiceRecord?.id) {
                throw new Error("currentServiceRecord hasn't been loaded");
            }

            const newDocument = await patchServiceRecordDocument(this.currentServiceRecord.id, this.currentServiceRecordDocument as ServiceRecordDocument, document);
            this.currentServiceRecordDocument = newDocument;

            // Update service record documents
            const recordIndex = this.serviceRecords.findIndex((record) => record.id === this.currentServiceRecord?.id);
            const documentIndex = (this.currentServiceRecord.serviceRecordDocuments as ServiceRecordDocument[])
                .findIndex((doc) => doc.id === this.currentServiceRecordDocument?.id);
            if (recordIndex > -1 || documentIndex > -1) {
                this.serviceRecords[recordIndex].serviceRecordDocuments?.splice(documentIndex, 1, newDocument);
            } else {
                this.serviceRecords[recordIndex].serviceRecordDocuments?.push(newDocument);
            }
            return newDocument;
        },

        async deleteServiceRecordDocument(id: string): Promise<void> {
            if (!this.currentServiceRecord?.id) {
                throw new Error("currentServiceRecord hasn't been loaded");
            }

            await deleteServiceRecordDocument(this.currentServiceRecord.id || "", id);
            const newRecord = Object.assign({}, this.currentServiceRecord);
            if (newRecord.serviceRecordDocuments) {
                const index = (this.currentServiceRecord.serviceRecordDocuments as ServiceRecordDocument[])
                    .findIndex((document) => document.id === this.currentServiceRecordDocument?.id);
                if (index > -1) {
                    (this.currentServiceRecord.serviceRecordDocuments as ServiceRecordDocument[]).splice(index, 1);
                }
            }
        }
    }
});

import { useOidcStore } from "@points/common";
import { NavigationGuardNext, Route } from "vue-router";

export function homeNavigationGuard(to: Route, from: Route, next: NavigationGuardNext): void {
    const oidcStore = useOidcStore();

    if (to.name === "home" && oidcStore.isAuthenticated) {
        next({ name: "dashboard" });
    } else {
        next();
    }
}

import App from "@/App.vue";
import { environment } from "@/utilities";
import PointSCommon from "@points/common";
import { CommonPluginOptions } from "@points/common/dist/models/CommonPluginOptions";
import { createPinia, PiniaVuePlugin } from "pinia";
import Vue, { VNode } from "vue";
import VueMoment from "vue-moment";
import Router from "vue-router";
import "./plugins/axios";
import vuetify from "./plugins/vuetify";
import { createRouter } from "./router";
import store from "./store";
import "./styles/app.scss";

Vue.config.productionTip = false;
Vue.config.devtools = !!environment.VUE_APP_DEVTOOLS;

// Register pinia
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

// Create router (depends on pinia)
Vue.use(Router);
const router = createRouter();

// Register common config/handler/components (depends on pinia and router)
Vue.use<CommonPluginOptions>(PointSCommon, {
    ...environment,
    pinia,
    router
});

Vue.use(VueMoment);

const {
    hostname,
    href,
    protocol
} = location;
if (hostname !== "localhost" && protocol === "http:") {
    location.replace(href.replace("http://", "https://"));
}

new Vue({
    router,
    vuetify,
    store,
    pinia,
    el: "#app",
    render: (h): VNode => h(App)
});


import { getVehicleInformation } from "@/api";
import { UserVehicle } from "@/models";
import { useAlltracCustomerStore, useUserVehicleStore } from "@/stores";
import { ODataQueryOptions, useActionStore } from "@points/common";
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AccountConnectionVehicleImport",

    data: () => ({
        availableVehicles: [] as UserVehicle[],
        vehicle: {} as UserVehicle
    }),

    computed: {
        ...mapState(useAlltracCustomerStore, ["allTracCustomerVehicles"]),

        selectedVehicles: {
            get(): UserVehicle[] {
                return this.allTracCustomerVehicles;
            },
            set(value: UserVehicle[]) {
                this.allTracCustomerVehicles = value;
            }
        }
    },

    updated() {
        if (!this.availableVehicles.length) {
            this.availableVehicles = this.allTracCustomerVehicles;
        }
    },

    methods: {
        ...mapActions(useActionStore, ["executeAction", "isActionPending"]),
        ...mapActions(useUserVehicleStore, ["saveVehicle"]),

        skipImportVehicles() {
            this.$emit("currentStep", 3);
        },

        async importVehicles(): Promise<void> {
            const vehiclesToImport = [];

            if (this.selectedVehicles.length) {
                for (const item of this.selectedVehicles) {
                    this.vehicle = Object.assign({}, item);

                    const oDataQueryOptions: ODataQueryOptions = {
                        "$filter": `yearId eq ${this.vehicle.yearId} and make eq '${this.vehicle.make}' and model eq '${this.vehicle.model}' and subModel eq '${this.vehicle.submodel}'`,
                        "$top": 1
                    };

                    const vehicles = await this.executeAction(
                        getVehicleInformation(oDataQueryOptions),
                        "accountConnectionVehicleImportGetVehicleInformation"
                    );

                    this.vehicle.vehicleId = vehicles[0].vehicleId;

                    vehiclesToImport.push(this.vehicle);
                }
            }

            for (const item of vehiclesToImport) {
                await this.saveVehicle(item);
            }

            this.$emit("currentStep", 3);
        }
    }
});


import PrivacyPolicyDialog from "@/components/PrivacyPolicyDialog.vue";
import TermsAndConditionsDialog from "@/components/TermsAndConditionsDialog.vue";
import { useServiceRecordStore, useUserStore } from "@/stores";
import { Loader } from "@googlemaps/js-api-loader";
import { ODataQueryOptions, useActionStore, useOidcStore } from "@points/common";
import { mapActions, mapState } from "pinia";
import { defineComponent, toRefs } from "vue";
import { syncServiceRecords } from "./api";
import VTextFieldPlaceSearch from "./components/VTextFieldPlaceSearch.vue";
import WelcomeNewUserDialog from "./components/WelcomeNewUserDialog.vue";
import { UserSettings } from "./models";

export default defineComponent({
    name: "App",

    components: {
        PrivacyPolicyDialog,
        TermsAndConditionsDialog,
        WelcomeNewUserDialog,
        VTextFieldPlaceSearch
    },
    setup() {
        const { isAuthenticated, userSettings } = toRefs(useOidcStore());
        return {
            isAuthenticated,
            userSettings
        };
    },

    data: () => ({
        drawer: false,
        hasGoogleMapsLoaded: false,
        navigationItems: [
            {
                id: 1,
                title: "Dashboard",
                icon: "fas fa-tachometer-alt fa-fw",
                link: "dashboard"
            },
            {
                id: 2,
                title: "Service History",
                icon: "fas fa-tools fa-fw",
                link: "serviceHistory"
            },
            {
                id: 3,
                title: "Virtual Garage",
                icon: "fas fa-car-side fa-fw",
                link: "virtualGarage"
            },
            {
                id: 4,
                title: "Store Finder",
                icon: "fas fa-map fa-fw",
                link: "storeLocations"
            }
        ],
        showNewUserDialog: false,
        showTermsAndConditions: false,
        showPrivacyPolicy: false
    }),
    computed: {
        ...mapState(useUserStore, ["currentUser"]),

        season() {
            const today = new Date();
            const season = Math.floor((today.getMonth() + 1) / 12 * 4) % 4;

            return ["winter", "spring", "summer", "autumn"][season];
        }
    },

    watch: {
        async userSettings() {
            const oidcStore = useOidcStore();

            if ((oidcStore.userSettings as UserSettings).accountsShowWelcomeDialog === false) {
                this.showNewUserDialog = false;
            } else if ((oidcStore.userSettings as UserSettings).accountsShowWelcomeDialog === true) {
                this.showNewUserDialog = true;
            } else {
                const newSettings = oidcStore.cloneUserSettings<UserSettings>();
                newSettings.accountsShowWelcomeDialog = true;
                newSettings.accountsSubscribeToAppointmentRemindersEmail = true;
                newSettings.accountsSubscribeToAppointmentRemindersPush = true;
                newSettings.accountsSubscribeToPromotionsEmail = true;
                newSettings.accountsSubscribeToRewardsEmail = true;
                newSettings.accountsSubscribeToRewardsPush = true;
                newSettings.accountsSubscribeToServiceRemindersEmail = true;
                newSettings.accountsSubscribeToServiceRemindersPush = true;
                await oidcStore.updateUserSettings(newSettings);
                this.showNewUserDialog = true;
            }
        },

        async currentUser() {
            if (this.currentUser?.allTracCustomerId) {
                await syncServiceRecords();

                const oDataQueryOptions: ODataQueryOptions = { "$orderBy": "serviceDate desc" };
                await this.loadServiceRecords(oDataQueryOptions);
            }
        }
    },

    async mounted() {
        await this.loadGoogleMaps();
    },

    methods: {
        ...mapActions(useActionStore, ["executeAction", "isActionPending"]),
        ...mapActions(useServiceRecordStore, ["loadServiceRecords"]),

        async loadGoogleMaps() {
            // TODO Move to secrets
            // const googleMapsApiKey = process.env.VUE_APP_GOOGLEMAPS_API_KEY;
            const googleMapsApiKey = "AIzaSyCNzNzMAZtAyQ67ltJx4pnKJsQ9h9z33HQ";

            const loader = new Loader({
                apiKey: googleMapsApiKey,
                version: "weekly",
                libraries: ["places"]
            });

            const result = await loader.load();

            if (result !== null) {
                this.hasGoogleMapsLoaded = true;
            }
        }
    }
});

import { AllTracCustomer, UserVehicle } from "@/models";
import { environment } from "@/utilities";
import { Page } from "@points/common";
import axios from "axios";

export async function postAllTracCustomer(customer: AllTracCustomer): Promise<AllTracCustomer> {
    const response = await axios.post<AllTracCustomer>(`${environment.VUE_APP_CENTRAL}/accounts/api/users/me/alltrac-customers`, customer);
    return response.data;
}

export async function getAllTracCustomerVehicles(id: number): Promise<UserVehicle[]> {
    if (!id) {
        throw new Error("id is required");
    }

    const response = await axios.get<Page<UserVehicle>>(`${environment.VUE_APP_CENTRAL}/alltrac/api/customervehicles?$filter=customerid eq ${id}`);
    return response.data.items;
}
